
    @import "config.scss";
 
.navbar {
  display: none;

  @include for-desktop-up {
    color: white;
    background-color: var(--color-grey);
    border-radius: 15px 15px 15px 15px;
    display: flex;
    position: relative;
    font-weight: lighter;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1440px;
    margin: auto;
    width: 100%;

    .navbarLink {
      display: flex;
      height: 3rem;
      justify-content: center;
      align-items: center;
      border-radius: 15px 15px 5px 5px;
    }

    .navbarItem {
      display: flex;
      height: 3rem;
      justify-content: center;
      align-items: center;
    }

    .selected {
      border-radius: 15px;
      color: var(--color-primary);
    }
  }
}

.open {
  border-radius: 15px 15px 5px 5px;
}
